import { Group, Text, TextInput } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import React, { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import FileService from "services/FileService";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleporVideoTextureUploadVideo({videoTexture}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            maxDistance: videoTexture.maxDistance / 10,
            origin: 'VIDEO'
        },
        validate: {
        },
    });

    const [debouncedForm] = useDebouncedValue(form.values, 2000);

    useEffect(() => {
        async function update() {
            if(debouncedForm.maxDistance * 10 === videoTexture.maxDistance)
                return;
            
            let validation = await form.validate();

            if(validation.hasErrors) {
                
            } else {
                updateData({values: debouncedForm})
            }
        }
        
        update();
    // eslint-disable-next-line
    }, [debouncedForm]);

    const uploadArea = useRef(null);

    const {mutate: updateData} = useMutation((data) => {       
        return BackendServiceV2.put(`/videotexture/${videoTexture.id}`, {
            maxDistance: data?.values?.maxDistance * 10,
            origin: data?.values?.origin
        });
    }, {
        onSuccess: (response, data) => {
            if(data?.upload) {
                generateUploadURL();
            } else {
                showNotification({
                    title: 'Vídeo atualizado com sucesso',
                    message: '',
                    autoClose: 5000,
                    color: 'green',
                    icon: <Check />
                })
                queryClient.invalidateQueries('teleport-video-texture');
            }
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const {mutate: generateUploadURL} = useMutation(() => {
        uploadArea.current.validate();
        return BackendServiceV2.put(`/videotexture/${videoTexture.id}/video`);
    }, {
        onSuccess: (response) => {            
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    return FileService.uploadFile(response, files, config);
                },
                success: () => {
                    form.reset();
                    uploadArea.current.reset();
                    queryClient.invalidateQueries('teleport-video-texture');
                    showNotification({
                        title: 'Script atualizado com sucesso',
                        message: '',
                        icon: <Check />,
                        color: 'green',
                        autoClose: 5000,
                    });
                },
                error: (error) => {
                    uploadArea.current.reset();
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: 5000,
                    });
                }
            });
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (<>
        <TextInput 
            label="Distancia máxima"
            type="number"
            rightSection={<Group align="left"><Text color="dimmed" size="sm">Metros</Text></Group>}
            rightSectionWidth={60}
            {...form.getInputProps('maxDistance')}
        />

        {videoTexture.path && <>
            <video width="100%" height={160} src={window.ENV.StaticURL + videoTexture.path} controls style={{backgroundColor: 'black'}}>
                Your browser does not support the video tag.
            </video>
        </>}

        <UploadArea
            onDrop={async (files) => {
                let validation = await form.validate();

                if(validation.hasErrors) {
                    uploadArea.current.reset();
                } else {
                    updateData({values: form.values, upload: true})
                }
            }}
            rejectMessage="Erro"
            maxSize={900 * 1024 ** 2}
            accept={[MIME_TYPES.mp4]}
            multiple={false}
            ref={uploadArea}
        />
    </>)
}