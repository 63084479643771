import React, { useState, useEffect, useContext} from 'react';
import './style.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { UserContext } from '../../context/User/UserContext'
import Home from './Home'
import Media from './Media';
import Error404 from './Error404'

import { SalesTeamEditPage } from './SalesTeam/SalesTeamEditPage';
import { SalesTeamListPage } from './SalesTeam/SalesTeamListPage';
import { Connect } from './Connect';
import { ResourcePermissionContextProvider } from 'context/Permission/ResourcePermissionContextProvider';
import { WebSocketContextProvider } from 'context/WebSocket/WebSocketContextProvider';
import { UserEditPage } from './User/UserEditPage';
import { UserListPage } from './User/UserListPage';
import { UserProfilePage } from './User/UserProfilePage';
import { TeleportEditPage } from './Teleport/TeleportEditPage';
import { DevelopmentEditPage } from './Development/DevelopmentEditPage';
import { TeleportListPage } from './Teleport/TeleportListPage';
import { CompanyEditPage } from './Company/CompanyEditPage';
import { CompanyListPage } from './Company/CompanyListPage';
import { DevelopmentListPage } from './Development/DevelopmentListPage';
import { TotemListPage } from './Totem/TotemListPage';
import { TotemEditPage } from './Totem/TotemEditPage';
import { ExpressListPage } from './Express/ExpressListPage';
import { ExpressEditPage } from './Express/ExpressEditPage';

function Dashboard() {
    const location = useLocation();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect (() => {
        let accessToken = localStorage.getItem('accessToken');
        let expireIn = localStorage.getItem('expireIn');
        
        if((accessToken === null || Date.now() >= expireIn) && process.env.NODE_ENV !== 'development') {
            localStorage.setItem("redirectTo", location.pathname);
            window.location.replace(window.ENV.BaseURL + '/id/login');
        } else {
            setIsAuthenticated(true);
        }
    }, [location, setIsAuthenticated]);

    const userContext = useContext(UserContext);

    useEffect(() => {
        let me = localStorage.getItem('me');
        userContext.setLoggedUserData(JSON.parse(me));
    // eslint-disable-next-line
    }, [])

    return (
        <>
            {isAuthenticated ? (
                <div className='dashboard'>
                    <WebSocketContextProvider>
                        <Sidebar />
                        <Routes>
                            <Route path="/profile" element={<UserProfilePage />} />
                            <Route path="/user/:idUser" element={<UserEditPage />} />
                            <Route path="/user" element={<UserListPage />} />
                            <Route path="/media" element={<Media />} />
                            <Route 
                                path="/teleport/:idTeleport" 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <TeleportEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route path="/teleport" element={<TeleportListPage />} />
                            <Route 
                                path="/development/:idDevelopment" 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <DevelopmentEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route 
                                path="/company/:idCompany" 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <CompanyEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route path="/company" element={<CompanyListPage />} />
                            <Route path="/development" element={<DevelopmentListPage />} />
                            
                            <Route 
                                path="/showcase/:idTotem" 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <TotemEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route path="/showcase" element={<TotemListPage />} />
                            
                            <Route 
                                path="/express/:idExpressProject" 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <ExpressEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route path="/express" element={<ExpressListPage />} />

                            <Route path="/404" element={<Error404 />} />
                            <Route 
                                path='/connect/:idSalesTeam' 
                                element={
                                    <ResourcePermissionContextProvider>
                                        <SalesTeamEditPage />
                                    </ResourcePermissionContextProvider>
                                } 
                            />
                            <Route path='/connect' element={<SalesTeamListPage />} />
                            <Route path='/call' element={<Connect />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </WebSocketContextProvider>
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default Dashboard;