import { Button, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { Files } from "tabler-icons-react";

export function TeleportRenderListPreview({render}) {
    
    const queryClient = useQueryClient();

    const resourcePermission = useContext(ResourcePermissionContext);

    const [isPreviewVisible, setPreviewVisibility] = useDisclosure(false);
    const [updateTime, setUpdateTime] = useState(0);

    const updateInterval = useRef();
    const timerInterval = useRef();

    useEffect(() => {
        if(!isPreviewVisible || render.phase !== 'RENDERING') {
            clearInterval(updateInterval.current);
            clearInterval(timerInterval.current);
            return;
        }

        setUpdateTime(60);

        updateInterval.current = setInterval(() => {
            queryClient.invalidateQueries('teleport-render-list');
            setUpdateTime(60);

            clearInterval(timerInterval.current);
            timerInterval.current = setInterval(() => {
                setUpdateTime(value => value - 1);
            }, 1000);
        }, 10000);

        timerInterval.current = setInterval(() => {
            setUpdateTime(value => value - 1);
        }, 1000);

        return () => {
            clearInterval(updateInterval.current);
            clearInterval(timerInterval.current);
        }
    // eslint-disable-next-line
    }, [isPreviewVisible]);

    return (<>
        <Modal
            opened={isPreviewVisible}
            onClose={setPreviewVisibility.close}
            title={
                <Group spacing={0} position="apart" grow>
                    <Group noWrap spacing={0}><Files /><Title order={2}>Arquivos</Title></Group>
                    {render.phase === 'RENDERING' && <Text size="xs" color="dimmed" style={{textAlign: 'right'}}>Atualiza em {updateTime} segundos</Text>}
                </Group>
            }
            styles={{
                title: {
                    width: '100%'
                }
            }}
        >
            <Stack spacing={0}>
                {render.resultFiles.map(resultFile => (<>
                    {(!resultFile?.relativePath?.includes('.txt') || (resultFile?.relativePath?.includes('.txt') && resourcePermission.containsGroup(['ADMIN'])))  &&
                        <a className="link-list" href={window.ENV.StaticURL + resultFile.path}>{resultFile.relativePath}</a>
                    }
                </>))}
            </Stack>
        </Modal>

        {render?.resultFiles?.length ?
            <Button variant='subtle' onClick={setPreviewVisibility.open}>Preview</Button>
        :
            <Text color="dimmed" size="xs" align="center" mt={10}>Preview indisponível</Text>
        }
    </>)
}