import { ContentWrapper, LeftContent, RightContent } from "components/v2/Dashboard/Layout";
import React from "react";
import { TeleportRenderList } from "../TeleportRenderList";
import { TeleportRenderUpload } from "../TeleportRenderUpload";

export function TeleportRender({teleport}) {
    return (
        <ContentWrapper>
            <LeftContent>
                <TeleportRenderList teleport={teleport} />
            </LeftContent>
            <RightContent>
                <TeleportRenderUpload teleport={teleport} />
            </RightContent>
        </ContentWrapper>
    )
}