import { Button, Checkbox, Stack, Text } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import UploadArea from "components/v2/UploadArea";
import React, { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import FileService from "services/FileService";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportLayerProcessing({layer}) {

    const queryClient = useQueryClient();

    // FORM CONFIG
    const form = useForm({
        initialValues: {}
    });

    // FORM SUBMIT
    const uploadArea = useRef(null);

    const {mutate: startProcessing} = useMutation((idProcessingRequest) => {
        return BackendServiceV2.post(`/layer/processing/${idProcessingRequest}/queue`)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-data');
            form.reset();
            
            showNotification({
                title: 'Processamento iniciado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: false
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    })

    const {mutate: createSignedUploadURL} = useMutation((data) => {
        uploadArea.current.validate();
        return BackendServiceV2.post(`/layer/${layer.id}/process/input`, data);
    }, {
        onSuccess: (response) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    return FileService.uploadFile(response?.url, files, config);
                },
                success: () => {
                    startProcessing(response.id);                    
                },
                error: (error) => {
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: false
                    });
                }
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    });    

    return (
        <SectionBox 
            title="Processar Teleport" 
            description="Processar um arquivo para gerar o Teleport"
        >
            <form onSubmit={form.onSubmit(values => createSignedUploadURL(values))}>
                <Stack>
                    <UploadArea 
                        accept={[MIME_TYPES.zip, 'application/x-zip-compressed']}
                        multiple={false}
                        ref={uploadArea}
                    />

                    <Text weight={600} size="xl">Processar:</Text>
                    <Checkbox
                        label="Skyboxes"
                        size="md"
                        {...form.getInputProps('skyboxes', { type: 'checkbox' })}
                    />

                    <Checkbox
                        label="Modelos"
                        size="md"
                        {...form.getInputProps('models', { type: 'checkbox' })}
                    />

                    {form.getInputProps('skyboxes').value && <>
                        <Text weight={600} size="xl">Opções do processamento de skyboxes</Text>
                        <Checkbox
                            label="Apenas atualizar skyboxes"
                            size="md"
                            {...form.getInputProps('skyboxesUpdateOnly', { type: 'checkbox' })}
                        />
                    </>}

                    <Button type="submit">Processar</Button>
                </Stack>

                
            </form>
        </SectionBox>
    )
}