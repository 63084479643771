import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { Badge, Button, Card, Group, SimpleGrid, Stack, Text } from "@mantine/core";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { SectionBox } from 'components/v2/SectionBox';
import { TeleportRenderListPreview } from '../TeleportRenderListPreview';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { TeleportRenderDeleteButton } from '../TeleportRenderDeleteButton';

export function TeleportRenderList({teleport}) {

    const resourcePermission = useContext(ResourcePermissionContext);

    const {data: renderList} = useQuery(['teleport-render-list', teleport.id], () => {
        return BackendServiceV2.get('/teleport/' + teleport.id + '/shot?size=100&page=0');
    });

    return (
        <SectionBox 
            title="Renders"
        >
            <SimpleGrid cols={2}>
                {renderList?.result?.map(render => (
                    <Card withBorder style={{}}>
                        <Group position='apart' noWrap>
                            <Group spacing={5}>
                                <Badge variant='filled'>{render.id}</Badge>
                                <Badge variant='outline'>{render.status}</Badge>
                                <Badge variant='outline'>{render.renderPhase}</Badge>
                            </Group>
                            <Group>
                                <TeleportRenderDeleteButton render={render} />
                            </Group>
                        </Group>

                        <Stack spacing={5}>
                            <Group position='apart' spacing={5}>
                                

                                <Group spacing={3}>
                                    <Text size="xs" color='dimmed' weight={600}>Iniciado por: </Text>
                                    <Text size="xs">{render.requestedBy?.name || 'Sistema'}</Text>
                                </Group>
                            </Group>

                            <Group position='apart'>
                                <Stack spacing={0}>
                                    <Text size="sm" weight={600}>Criado</Text>
                                    {render.createdAt && <Text size="xs">{dayjs(render.createdAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text>}
                                </Stack>
                                
                                <Stack spacing={0}>
                                    <Text size="sm" weight={600}>Iniciado</Text>
                                    {render.startedAt ? 
                                        <Text size="xs">{dayjs(render.startedAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text> 
                                    : 
                                        <Text color='dimmed' size="xs">Não iniciado</Text>
                                    }
                                </Stack>

                                <Stack spacing={0}>
                                    <Text size="sm" weight={600}>Finalizado</Text>
                                    {render?.finishedAt ? 
                                        <Text size="xs">{dayjs(render.finishedAt).locale('pt-br').format('DD/MM/YYYY HH:mm')}</Text> 
                                    : 
                                        <Text color='dimmed' size="xs">Não finalizado</Text>
                                    }
                                </Stack>
                            </Group>

                            <Group grow>
                                {render?.resultFilesZip &&
                                    <Button component='a' href={window.ENV.StaticURL + render.resultFilesZip}>Resultado</Button>
                                }

                                {resourcePermission.containsGroup(['ADMIN']) &&
                                    <Button component='a' href={window.ENV.StaticURL + render.baseFile}>Original</Button>
                                }
                            </Group>

                            <TeleportRenderListPreview render={render} />
                        </Stack>
                    </Card>
                ))}
            </SimpleGrid>
        </SectionBox>
    )
}