import { Badge, Group, SegmentedControl, Stack, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { TeleportVideoTextureDeleteButton } from "../TeleportVideoTextureDeleteButton";
import { TeleporVideoTextureUploadVideo } from "../TeleportVideoTextureUploadVideo";
import { TeleportVideoTextureLiveVideo } from "../TeleportVideoTextureLiveVideo";

export function TeleportVideoTextureCard({videoTexture}) {

    // QUERY CLIENT
    const queryClient = useQueryClient();

    const [selectedTab, setSelectedTab] = useState(videoTexture.origin);

    // UPDATE DATA ON QUERY INVALIDATE AND AUTO LIVE STARTING UPDATE
    useEffect(() => {
        let updateInterval;
        if(videoTexture?.stream && videoTexture?.stream?.status !== 'RUNNING') {
            updateInterval = setInterval(() => {
                console.log("UPDATING VIDEO TEXTURE DATA");
                queryClient.invalidateQueries('teleport-video-texture');
            }, 10000);
        } else {
            if(updateInterval)
                clearInterval(updateInterval)
        }

        return () => {
            if(updateInterval)
                clearInterval(updateInterval);
        }
    // eslint-disable-next-line
    }, [videoTexture]);    
    
    return (
        <Stack spacing={5} style={{border: '1px solid #eee', padding: '15px', borderRadius: '5px'}}>
            <Group position="apart">
                <Group>
                    <Title order={4}>{videoTexture.name}</Title>
                    <Badge variant="outline" size="xs">{videoTexture.id}</Badge>
                </Group>
                <TeleportVideoTextureDeleteButton videoTexture={videoTexture} />
            </Group>

            {videoTexture?.stream?.status !== 'RUNNING' &&
                <SegmentedControl
                    data={[
                        { label: 'Video', value: 'VIDEO' },
                        { label: 'Live', value: 'LIVE' },
                    ]}
                    value={selectedTab}
                    onChange={setSelectedTab}
                />
            }

            {selectedTab === 'LIVE' && 
                <TeleportVideoTextureLiveVideo videoTexture={videoTexture} />
            }

            {selectedTab === 'VIDEO' && <>
                <TeleporVideoTextureUploadVideo videoTexture={videoTexture} />
            </>}
        </Stack>
    )    
}