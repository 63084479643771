const OSS = require('ali-oss');

let FileService = {
    uploadFile: (data, files, config) => {
        let OSSClient = new OSS({
            region: 'oss-us-east-1',
            accessKeyId: data?.accessKey,
            accessKeySecret: data?.accessSecret,
            stsToken: data?.securityToken,
            refreshSTSToken: async () => {
                const info = await fetch('sts.us-east-1.aliyuncs.com');
                return {
                    accessKeyId: info.accessKeyId,
                    accessKeySecret: info.accessKeySecret,
                    stsToken: info.stsToken
                }
            },
            refreshSTSTokenInterval: 300000,
            bucket: data?.bucketName,
            secure: true
        });
        
        const options = {
            progress: (p, cpt, res) => {
                config.progress(p);
            },
            parallel: 4,
            partSize: 1024 * 1024
        };

        return OSSClient.multipartUpload(data?.entityDir + data?.filename, files[0], {
            ...options
        })
    }
}

export default FileService;