import { ActionIcon, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Trash, X } from "tabler-icons-react";

export function TeleportVideoTextureDeleteButton({videoTexture}) {

    const queryClient = useQueryClient();

    const modals = useModals();
    const deleteVideoTextureConfirmation = () => modals.openConfirmModal({
        title: 'Deletar textura',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar a textura {videoTexture.name}?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteVideoTexture();
        },
        zIndex: 1000
    });

    const {mutate: deleteVideoTexture} = useMutation(() => {
        return BackendServiceV2.delete(`/videotexture/${videoTexture.id}`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Vídeo removido com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (
        <ActionIcon variant="transparent" onClick={e => {deleteVideoTextureConfirmation()}} color="#ff5e00"><Trash /></ActionIcon>
    )
}