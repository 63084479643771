import { Button, Group, Loader, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { CopyTextInput } from "components/v2/Form/CopyTextInput";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportVideoTextureLiveVideo({videoTexture}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            maxDistance: videoTexture.maxDistance / 10,
            origin: 'LIVE'
        },
        validate: {
        },
    });

    const [debouncedForm] = useDebouncedValue(form.values, 2000);

    useEffect(() => {
        async function update() {
            if(debouncedForm.maxDistance * 10 === videoTexture.maxDistance)
                return;
            
            let validation = await form.validate();

            if(validation.hasErrors) {
                
            } else {
                updateData({values: debouncedForm})
            }
        }
        
        update();
    // eslint-disable-next-line
    }, [debouncedForm]);

    const {mutate: updateData} = useMutation((data) => {
        console.log(data);
        
        return BackendServiceV2.put(`/videotexture/${videoTexture.id}`, {
            maxDistance: data?.values?.maxDistance * 10,
            origin: data?.values?.origin
        });
    }, {
        onSuccess: (response) => {
            showNotification({
                title: 'Vídeo atualizado com sucesso',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const {mutate: startLiveStreaming} = useMutation(() => {
        return BackendServiceV2.post(`/videotexture/${videoTexture.id}/live`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Transmissão ao vivo iniciando.',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    const {mutate: closeLiveStreaming} = useMutation(() => {
        return BackendServiceV2.delete(`/videotexture/${videoTexture.id}/live`);
    }, {
        onSuccess: data => {
            showNotification({
                title: 'Transmissão ao vivo encerrada.',
                message: '',
                autoClose: 5000,
                color: 'green',
                icon: <Check />
            })
            queryClient.invalidateQueries('teleport-video-texture');
        },
        onError: error => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                autoClose: 5000,
                color: 'red',
                icon: <X />
            })
        }
    })

    return (
        <>
            <TextInput 
                label="Distancia máxima"
                type="number"
                rightSection={<Group align="left"><Text color="dimmed" size="sm">Metros</Text></Group>}
                rightSectionWidth={60}
                {...form.getInputProps('maxDistance')}
            />

            {videoTexture.stream ?
                <>
                    {videoTexture.stream.status === 'RUNNING' && <>
                        <CopyTextInput
                            label="Servidor RTMP"
                            value={'rtmp://' + videoTexture.stream.ip + '/live'}
                        />

                        <CopyTextInput
                            label="Chave"
                            notificationMessage="Chave copiada com sucesso"
                            value={videoTexture.stream.key}
                        />

                        <Button title="Iniciar livestream" onClick={e => {closeLiveStreaming()}}>Encerrar livestream</Button>
                    </>}

                    {videoTexture.stream.status !== 'RUNNING' && <>
                        <Stack align="center" spacing="xs" mt="xs">
                            <Loader />
                            <Text size="xs" align="center" color="dimmed">Transmissão iniciando, por favor, aguarde.</Text>
                        </Stack>
                    </>}
                </>
            :
                <Button title="Iniciar livestream" onClick={e => {startLiveStreaming()}}>Iniciar livestream</Button>
            }
        </>
    )
}